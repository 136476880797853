exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innoinvest-js": () => import("./../../../src/pages/innoinvest.js" /* webpackChunkName: "component---src-pages-innoinvest-js" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-audanika-de-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/audanika.de.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-audanika-de-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-audanika-en-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/audanika.en.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-audanika-en-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-object-employer-branding-de-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/object_employer_branding.de.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-object-employer-branding-de-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-object-employer-branding-en-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/object_employer_branding.en.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-object-employer-branding-en-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-treemboards-de-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/treemboards.de.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-treemboards-de-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-treemboards-en-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/treemboards.en.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-treemboards-en-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-tt-lmk-position-de-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/tt_lmk_position.de.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-tt-lmk-position-de-mdx" */),
  "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-tt-lmk-position-en-mdx": () => import("./../../../templates/project-page.js?__contentFilePath=/Users/dennynguyen/Developer/foxel_website/projects/tt_lmk_position.en.mdx" /* webpackChunkName: "component---templates-project-page-js-content-file-path-users-dennynguyen-developer-foxel-website-projects-tt-lmk-position-en-mdx" */)
}

